(function ($, root, undefined) {

	$(function () {

		'use strict';

    // https://github.com/miguel-perez/smoothState.js
    // var $body = $('body');
    // var $loader = $('#loader');
    // var $transition = $('.transition-box');

    // var $page = $('#main'),
    // options = {
    //   debug: true,
    //   blacklist: ".no-smoothstate, a[href*='.jpg'], a[href*='.png'], a[href*='.jpeg'], a[href*='.pdf']",
    //   prefetch: false,
    //   loadingClass: 'onload',
    //   scroll: false,
    //   cacheLength: 0,
    //   forms: 'search',
    //   onStart: {
    //     duration: 0, // Duration of our animation
    //     render: function ($container) {
    //       // Add your CSS animation reversing class
    //       // $container.addClass('onload');
    //       // $loader.addClass("start");
    //       // Restart your animation
    //       smoothState.restartCSSAnimations();
    //       $body.addClass("beforeload");
    //     }
    //   },
    //   onProgress: {
    //     // How long this animation takes
    //     duration: 1300,
    //     // A function that dictates the animations that take place
    //     render: function ($container) {
    //       $body.removeClass("loaded");
    //       $transition.addClass("moving-effect");
    //       $body.removeClass("beforeload");
    //       // $body.addClass("onload");
    //     }
    //   },
    //   onReady: {
    //     duration: 0,
    //     render: function ($container, $newContent) {
    //       // Remove your CSS animation reversing class
    //       // $container.removeClass('onload');
    //       // $body.removeClass("onload");
    //       $body.addClass("loaded");
    //       $transition.removeClass("moving-effect");
    //       // $(".wrapLoader").removeClass("loading");
    //       // Inject the new content
    //       $container.html($newContent);
    //       $('html, body').animate({ scrollTop: 0 }, 0);
    //       initSite();
    //     }
    //   },
    //   onAfter : function($container, $newContent) {
    //       // $body.removeClass("onload");
    //       // $loader.removeClass("start");
    //   }
    // },
    // smoothState = $page.smoothState(options).data('smoothState');

    // -------- End smoothState ------------


    // Fonction fixe navigation

    fixeNav();

    function fixeNav() {
      var header = $("html");
      $(window).scroll(function() {
        var scroll = $(window).scrollTop();
          if (scroll >= 60) {
              header.addClass("navfixed");
          } else {
              header.removeClass("navfixed");
          }
      });
    }

    // $(window).scroll(function() {
    //   var scroll = $(window).scrollTop();
    //   $(".zoom").css({
    //   backgroundSize: (100 + scroll/20)  + "%",
    //   bottom: -(scroll/10)  + "%",
    //       });
    // });

		// $('a:not([href])').click(function(e) {
		//     e.preventDefault();
		//     window.location.href = '/';
		// });

		// $('.nav a[href^="/#"]').on('click', function (e) {
		// 	$('#toggle-nav').removeAttr('checked');
		// });

    // $('.nav a').on('click', function (e) {
    //   $('#toggle-nav').removeAttr('checked');
    // });


    // Fonction d'activation class active sur bouton


    initSite();

    function initSite() {

      // console.log("INIT SITE");

      // Detetection du touch avec Modernizr

      if ($('html').hasClass('no-touch')) {
          // console.log("No touch !!")
      } else {
          // console.log("touch !!")
          // alert("Touch");
      }

      // Detetection du touch avec Modernizr pour remove pop sur vidéos

      // if ($('html').hasClass('no-touch')) {
      //   // alert("No touch !!")
      //   $('.linkPop').vanillabox({
      //     type: 'iframe',
      //     preferredWidth: 1024,
      //     preferredHeight: 576,
      //     closeButton: true,
      //     repositionOnScroll: true,
      //     grouping: false
      //   });

      // } else {
      //   // alert("touch !!")
      //   $('#realisations a').removeClass('videos-pop');
      //   $("#realisations a").attr( "target", "_blank" );
      // }


      // https://github.com/nextapps-de/spotlight



      // Si l'on est sur la Home

      if ($('body').hasClass('home')) {

      } else {
        // console.log("Pas la Home !!");
      }

      // Effetts apparitions
      // https://scrollrevealjs.org/api/reveal.html

      // window.sr = ScrollReveal({ reset: false });

      // sr.reveal('.effetReveal', {
      //   duration: 600,
      //   delay: 300,
      //   scale: 1,
      //   mobile: false,
      //   useDelay: 'once',
      //   origin: 'left',
      //   distance: '200px',
      //   viewFactor: 0.3
      // });


      // if ($('.diaporama').length) {

      //   $('.diapfull a').vanillabox({
      //     preferredWidth: 1024,
      //     preferredHeight: 576,
      //     closeButton: true,
      //     repositionOnScroll: true,
      //     grouping: true
      //   });

      //   $('.diaplight a').vanillabox({
      //     preferredWidth: 1024,
      //     preferredHeight: 576,
      //     closeButton: true,
      //     repositionOnScroll: true,
      //     grouping: false
      //   });

      // }


      //https://lokeshdhakar.com/projects/lightbox2/#examples

      lightbox.option({
        'resizeDuration': 500,
        'albumLabel' : false
      });


      $(".sliderHome").owlCarousel({
        items: 1,
        loop: true,
        autoplay: true,
        mouseDrag: false,
        autoplayTimeout: 4000,
        autoplayHoverPause: false,
        nav: false,
        navText: [ '', '' ],
        dots: true,
        smartSpeed: 700,
        animateOut: 'fadeOut',
        responsiveClass:true,
        responsive:{
            0:{
                nav:false
            },
            600:{

            },
            979:{

            }
        }
      });

      $(".diaporama").owlCarousel({
        items: 1,
        loop: false,
        autoplay: false,
        mouseDrag: false,
        autoplayTimeout: 4000,
        autoplayHoverPause: false,
        nav: true,
        navText: [ '', '' ],
        dots: false,
        smartSpeed: 700,
        animateOut: 'fadeOut',
        responsiveClass:true,
        responsive:{
            0:{
                nav:false
            },
            600:{

            },
            979:{

            }
        }
      });

      $("#carouselTextes").owlCarousel({
        items: 1,
        loop: false,
        autoplay: false,
        mouseDrag: false,
        autoplayTimeout: 4000,
        autoplayHoverPause: false,
        nav: true,
        navText: [ '&larr;', '&rarr;' ],
        dots: false,
        smartSpeed: 700,
        animateOut: 'fadeOut',
        responsiveClass:true,
        responsive:{
            0:{

            },
            720:{

            },
            1040:{

            }
        }
      });


      // Toogle Navigation Mobile

      $('.nav').find('.menu-item-has-children').click(function(){
        // $(this).parent().toggleClass("active");
        //Expand or collapse this panel
        $(".menu-item-has-children").not($(this)).removeClass('active');

        // console.log("hello ");
        $(this).toggleClass('active');
        // $(this).siblings('.sub-menu').slideDown(500);
        //Hide the other panels

        // if ($(this).parent().hasClass('active')) {
        //   console.log("Open!")
        //   $(this).siblings('.sub-menu').slideDown(500);
        // } else {
        //   console.log("close")
        //   $(this).siblings('.sub-menu').slideUp(500);
        // }

      });


    }

    // AFTER INIT SITE

	});

})(jQuery, this);

(function($, undefined) {
    var isFired = false;
    var oldReady = jQuery.fn.ready;
    $(function() {
        isFired = true;
        $(document).ready();
    });
    jQuery.fn.ready = function(fn) {
        if(fn === undefined) {
            $(document).trigger('_is_ready');
            return;
        }
        if(isFired) {
            window.setTimeout(fn, 1);
        }
        $(document).bind('_is_ready', fn);
    };
})(jQuery);
